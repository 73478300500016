<template lang="pug">
  header.header
    div.container-header.container
      nav.header__wrapper
        router-link(:to="{ path: '/', hash: '#hero' }").logo
          img(:src="Logo" alt="logo")
        div.header__box
          router-link(:to="{ path: '/', hash: '#contact' }") Contact Us
          a(href="https://apps.apple.com/us/app/weather-forecast-live-radar/id6472042471" target="_blank").btn Get the app
</template>

<script>
import Logo from '@/assets/images/avalon.svg'

export default {
  name: 'HeaderBlock',
  data () {
    return {
      Logo
    }
  }
}
</script>
